import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.DeferringHost
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.pkg_dot_zip.kobwebportfoliopkgdotzip.pages.HomePage() }
        ctx.router.register("/about") { com.pkg_dot_zip.kobwebportfoliopkgdotzip.pages.AboutPage() }
        ctx.router.register("/repo-page") {
                com.pkg_dot_zip.kobwebportfoliopkgdotzip.pages.RepoPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("headline-text",
                com.pkg_dot_zip.kobwebportfoliopkgdotzip.HeadlineTextStyle)
        ctx.theme.registerStyle("subheadline-text",
                com.pkg_dot_zip.kobwebportfoliopkgdotzip.SubheadlineTextStyle)
        ctx.theme.registerStyle("markdown",
                com.pkg_dot_zip.kobwebportfoliopkgdotzip.components.layouts.MarkdownStyle)
        ctx.theme.registerStyle("page-content",
                com.pkg_dot_zip.kobwebportfoliopkgdotzip.components.layouts.PageContentStyle)
        ctx.theme.registerStyle("footer",
                com.pkg_dot_zip.kobwebportfoliopkgdotzip.components.sections.FooterStyle)
        ctx.theme.registerStyle("nav-header",
                com.pkg_dot_zip.kobwebportfoliopkgdotzip.components.sections.NavHeaderStyle)
        ctx.theme.registerStyle("scale-on-hover-animation",
                com.pkg_dot_zip.kobwebportfoliopkgdotzip.components.widgets.scaleOnHoverAnimation)
        ctx.theme.registerStyle("hero-container",
                com.pkg_dot_zip.kobwebportfoliopkgdotzip.pages.HeroContainerStyle)
        ctx.theme.registerStyle("home-grid",
                com.pkg_dot_zip.kobwebportfoliopkgdotzip.pages.HomeGridStyle)
        ctx.theme.registerStyle("home-grid-cell",
                com.pkg_dot_zip.kobwebportfoliopkgdotzip.pages.HomeGridCellStyle)
        ctx.theme.registerVariant("-circle",
                com.pkg_dot_zip.kobwebportfoliopkgdotzip.CircleButtonVariant
        )
        ctx.theme.registerVariant("-uncolored",
                com.pkg_dot_zip.kobwebportfoliopkgdotzip.UncoloredButtonVariant
        )
        ctx.theme.registerKeyframes("side-menu-slide-in",
                com.pkg_dot_zip.kobwebportfoliopkgdotzip.components.sections.SideMenuSlideInAnim)
        com.pkg_dot_zip.kobwebportfoliopkgdotzip.initColorMode(ctx)
        com.pkg_dot_zip.kobwebportfoliopkgdotzip.initSiteStyles(ctx)
        com.pkg_dot_zip.kobwebportfoliopkgdotzip.initTheme(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "KobwebPortfolioPkgDotZip"))
    renderComposable(rootElementId = "root") {
        com.pkg_dot_zip.kobwebportfoliopkgdotzip.AppEntry {
            router.renderActivePage { DeferringHost { it() } }
        }
    }
}
