package com.pkg_dot_zip.kobwebportfoliopkgdotzip.util.date

import com.pkg_dot_zip.kobwebportfoliopkgdotzip.util.Logger
import kotlin.js.Date

data class WebDate(
    var years: Int = 0,
    var months: Int = 0,
    var days: Int = 0,
    var hours: Int = 0,
    var minutes: Int = 0,
    var seconds: Int = 0,
) {
    fun onSameDateAndYearAndHour(other: WebDate): Boolean = this.hours == other.hours && onSameDateAndYear(other)
    fun onSameDateAndYear(other: WebDate): Boolean = this.years == other.years && onSameDate(other)
    fun onSameDate(other: WebDate): Boolean = this.months == other.months && this.days == other.days
    fun toDate(): Date = Date(this.years, this.months, this.days, this.hours, this.minutes, this.seconds)

    companion object {
        private val logger = Logger.get { }

        fun now(): WebDate = parseDate(Date()).apply {
            logger.warning("Manually applying hardcoded timezone fix")
            this.hours += 2 // HARDCODED TIMEZONE FIX.
        }

        private fun parseDate(date: Date): WebDate = parseString(date.toISOString())

        private fun parseString(dateTimeString: String): WebDate = WebDate(
            years = parseDate(dateTimeString).first,
            months = parseDate(dateTimeString).second,
            days = parseDate(dateTimeString).third,
            hours = parseTime(dateTimeString).first,
            minutes = parseTime(dateTimeString).second,
            seconds = parseTime(dateTimeString).third,
        )

        private fun parseDate(dateTimeString: String): Triple<Int, Int, Int> {
            val datePart = dateTimeString.split("T")[0]
            val (year, month, day) = datePart.split("-").map { it.toInt() }
            return Triple(year, month, day)
        }

        private fun parseTime(dateTimeString: String): Triple<Int, Int, Int> {
            val timePart = dateTimeString.split("T")[1].split("Z")[0]
            val (hour, minute, sec) = timePart.split(":")
            val seconds = sec.split(".")[0].toInt()
            return Triple(hour.toInt(), minute.toInt(), seconds)
        }
    }
}