package com.pkg_dot_zip.kobwebportfoliopkgdotzip.util

class Logger private constructor(private val className: String) {
    private fun printToConsole(msg: String) = println(msg)
    private fun printLog(emoji: String, msg: String, className: String = this.className) =
        printToConsole("$emoji $className - $msg")

    fun trace(str: String) = printLog("\uD83D\uDD0E", str)
    fun info(str: String) = printLog("\uD83D\uDDA8\uFE0F", str)
    fun debug(str: String) = printLog("\uD83E\uDDEA", str)
    fun warning(str: String) = printLog("⚠\uFE0F", str)
    fun error(str: String) = printLog("⛔", str)

    companion object {
        fun get(func: () -> Unit): Logger = get("") // TODO: Somehow retrieve name by func.
        fun get(name: String): Logger = Logger(name)
    }
}